<template>
  <div>
    <v-progress-circular
      v-show="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div v-if="error.length">
      <v-card width="400px">
        <v-card-text class="text-center">
          <div class="error--text text-h5">{{ error }}</div>
          <div class="mb-1 mt-4">
            <v-btn :to="{ name: 'ForgetPassword' }" color="primary"
              >Mot de passe oublié</v-btn
            >
          </div>
          <div class="my-1">
            <v-btn :to="{ name: 'Login' }" color="primary">Se connecter</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'

export default {
  name: 'LinkConnect',
  data() {
    return {
      loading: true,
      error: '',
    }
  },
  methods: {
    connect() {
      this.loading = true
      requestService
        .post('api/user/link-connect', {
          key: this.$route.params.key,
        })
        .then(response => {
          if (!response.token) {
            this.error = response.message
          } else {
            this.$store.dispatch('account/loginSuccess', { data: response })
            if (this.$route.query.action === 'reset_password') {
              return this.$router.push({ name: 'ResetPassword' })
            }
            this.$router.push({ name: 'Home' })
          }
        })
        .finally(() => (this.loading = false))
    },
  },
  created() {
    this.connect()
  },
}
</script>

<style scoped></style>
